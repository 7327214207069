<template>
  <div
    id="app"
  >
    <div
      class="admin-container"
    >
      <router-view />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'App',
    // components: {
    //   Login,
    // },
    methods: {
    },
  }
</script>
